<template>
<div id="projects">
    <vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">{{ news.title }}</h2>
                </div>
            </div>
        </div>
    </section>
    <div class="container content" style="padding-top: 30px;">
        <div id="property-listings">
            <div class="row">
                <div class="col-sm-12">
                    <div class="skill-home">
                        <div class="skill-home-solid clearfix">
                            <div class="text-center">
                                <span class="icons c1">
									<img style="width: 175px; height: 175px;" class="img-responsive" :src="$uploadsURL+'news/'+news.image" alt="">
                                </span>
                                <div class="box-area">
                                    <div class="media-body fnt-smaller">
                                        <h2 v-html="news.title" class="media-heading"></h2>
                                        <p v-html="news.description" class="hidden-xs"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script type="text/javascript" scoped>
export default{
	data(){
		return {
			news: {
				title: "",
				description: "",
				image: "",
			},
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_news/"+this.$route.params.slug,
		}).then((res) => {
			if(!res.data){
				this.$router.push("/");
			}
			this.news = res.data;
			this.$refs.topProgress.done();
		});
	},
}
</script>

<style type="text/css" scoped>
</style>